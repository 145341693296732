module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"riff-gatsby-starter-contentful","short_name":"Contentful Starter","start_url":"/","icon":"src/assets/images/favicon.png","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aad87378282125fe52760fff17b080"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fresnel/gatsby-browser.js'),
      options: {"plugins":[],"breakpoints":{"mobile":0,"tablet":600,"desktop":1280,"desktop_large":1920}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://p.typekit.net"],"web":[{"name":["oswald","trade-gothic-next"],"file":"https://use.typekit.net/ajv5vvf.css"}],"custom":[{"name":["Larken Extra Bold","Larken Extra Bold Italic","Larken Italic","Larken Regular"],"file":"/fonts/styles.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
